import React from 'react'
import Footer from './footer'
import Header from './header'

interface Props {
  baseFontSize?: number
}

const PatientPrivacyPage: React.FunctionComponent<Props> = ({ baseFontSize = 16 }) => (
  <div>
    <Header title="Informativa Privacy Paziente" />
    <div css={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <div
        css={{
          maxWidth: 960,
          padding: 16,
          margin: '16px auto',
        }}
      >
        <div
          css={{
            h1: {
              fontWeight: 700,
              fontSize: baseFontSize + 2,
              marginBottom: 8,
              lineHeight: 1.2,
            },
            h2: {
              fontWeight: 700,
              fontSize: baseFontSize + 1,
              marginTop: 16,
              marginBottom: 8,
              lineHeight: 1.2,
            },
            b: {
              fontWeight: 400,
            },
            p: {
              fontSize: baseFontSize,
              marginBottom: 4,
              lineHeight: 1.2,
            },
            'ul, ol': {
              fontSize: baseFontSize,
              margin: '8px 8px',
              lineHeight: 1.2,
            },
            li: {
              margin: '4px 0',
            },
          }}
        >
          <h1>
            INFORMATIVA N. GEP2007 SULLA PROTEZIONE DEI DATI PERSONALI AI SENSI DEL REGOLAMENTO UE 2016/679 (“GDPR”) E
            DEL D.LGS. 196/2003, COME MODIFICATO DAL D.LGS. N. 101/2018
          </h1>
          <h2>Premessa</h2>
          <p>
            Egg S.r.l., con sede legale in Milano (MI), Via Visconti di Modrone nr. 30 – Codice Fiscale e P. Iva
            09477660964 (qui di seguito “EGG”) - ai sensi dell’art. 13 del Regolamento (UE) n. 679/16 (“GDPR”) -
            nell’ambito del Programma di supporto al paziente “Tpenso” (qui di seguito Programma), con la presente
            intende metterle a disposizione alcune informazioni circa il trattamento dei dati personali che La
            riguardano o che riguardano il minore su cui lei esercita la potestà genitoriale o la tutela ex art. 357
            c.c. nel caso sia il minore a partecipare al Programma (di seguito, “Minore”).
          </p>
          <h2>1. Categorie di dati personali trattati da EGG</h2>
          <p>
            I dati personali oggetto di trattamento da parte di EGG ai fini del loro trattamento nell’ambito del
            Programma sono i seguenti:
          </p>
          <ol>
            <li>i) i dati anagrafici quali nome, cognome, data, luogo di nascita, sesso;</li>
            <li>
              ii) i dati di contatto quali indirizzo di residenza e/o domicilio, telefono fisso e/o cellulare, indirizzo
              email;
            </li>
            <li>
              iii) i dati relativi allo stato di salute, i quali sono necessari per la sua partecipazione o del Minore
              al Programma, incluse le informazioni sulla patologia e terapia, stile di vita e di benessere. Nel corso
              del Programma EGG potrà raccogliere ulteriori informazioni dalle risposte da Lei direttamente fornite
              durante gli incontri e/o i contatti con gli Operatori Sanitari, o eventualmente fornite dal suo medico
              curante, informazioni che, ove ne abbiano le caratteristiche, verranno trattate secondo i termini previsti
              per le “categorie particolari di dati personali” (nel caso di Minore, non si tratteranno dati relativi
              alla salute di colui che esercita la potestà genitoriale o la tutela ex art. 357 c.c.). Il trattamento di
              questi dati sarà soggetto al consenso, che le verrà espressamente richiesto al momento della
              sottoscrizione del modulo di adesione al Programma
            </li>
          </ol>
          <h2>2. Finalità - base giuridica del trattamento</h2>
          <p>
            2.1. I Dati Personali saranno utilizzati esclusivamente per consentire l’adempimento di tutti gli obblighi
            derivanti dalla sua partecipazione o quella del Minore al Programma ed, in particolare, per le seguenti
            finalità:
          </p>
          <ol>
            <li>
              a) organizzazione e gestione dei servizi oggetto del Programma cui lei volontariamente ed espressamente
              aderisce;
            </li>
            <li>
              b) invio di comunicazioni di servizio e materiale informativo relativo al Programma tramite contatto
              telefonico, invio di sms o comunicazioni email;
            </li>
            <li>c) esecuzione degli adempimenti di farmacovigilanza;</li>
            <li>d) gestione di eventuali attività logistiche inerenti il Programma;</li>
            <li>
              e) gestione di eventuale attività di smaltimento di rifiuti sanitari derivanti dai servizi oggetto del
              Programma;
            </li>
            <li>
              f) trasferimento a eventuali nuovi Provider dei dati personali che riguardano Lei o il Minore che aderisce
              al Programma per assicurare la continuità dei servizi oggetto del Programma stesso, in caso di nuovo
              affidamento;
            </li>
            <li>
              g) finalità statistiche e di analisi. In relazione a detta finalità saranno utilizzati esclusivamente dati
              anonimi ed aggregati, attuando logiche che garantiscano l’impossibilità di risalire anche indirettamente a
              Lei o al Minore che aderisce al Programma.
            </li>
          </ol>
          <p>2.2 La base giuridica del trattamento:</p>
          <ul>
            <li>
              - relativamente alle attività di cui alle sub 2.1. lettere da a) a e), la base giuridica risiede nella sua
              adesione al Programma e nell’esecuzione degli obblighi contrattuali per la realizzazione dello stesso
            </li>
            <li>
              - relativamente all’ attività di cui alla sub 2.1. lettera f) la base giuridica é il suo consenso. La
              revoca del consenso, prima del trasferimento per qualsiasi motivo dei dati personali al nuovo affidatario,
              o il mancato conferimento del consenso alla comunicazione dei dati al nuovo affidatario del Programma
              comporterà l’impossibilità da parte di EGG di trasferire al nuovo Provider i Dati Personali e di
              conseguenza l’impossibilità di assicurare la continuità dei servizi oggetto del Programma in caso di nuovo
              affidamento degli stessi.
            </li>
          </ul>
          <h2>3. Natura del trattamento</h2>
          <p>3.1 La natura del trattamento:</p>
          <ul>
            <li>
              - relativamente alle attività di cui al punto 2.1 sub lettere da a) ad e), la natura del trattamento è
              necessaria, pertanto l'eventuale rifiuto a fornirli, in tutto o in parte, può avere come conseguenza
              l’impossibilità da parte di EGG di dare esecuzione al Programma;
            </li>
            <li>
              - relativamente alle attività di cui alla sub lettera f), la natura del trattamento è facoltativa,
              pertanto l'eventuale rifiuto a fornirli, in tutto o in parte, ovvero la mancata autorizzazione al loro
              trattamento e/o alla loro comunicazione a terzi coinvolti nello svolgimento dell’attività può avere come
              conseguenza l’impossibilità da parte di EGG. di dar corso al trasferimento e di conseguenza
              l’impossibilità per lei di proseguire nel Programma.
            </li>
          </ul>
          <h2>4. Modalità del trattamento </h2>
          <p>
            Il trattamento dei Dati Personali è realizzato sia in modalità cartacea che con l’ausilio di mezzi
            elettronici e/o informatici.
          </p>
          <p>I Dati Personali saranno conservati su server ubicati in Europa.</p>
          <h2>5. Titolare del Trattamento</h2>
          <p>
            Il Titolare del trattamento dei suoi Dati Personali è- EGG S.r.l., con sede legale in Milano (MI), Via
            Visconti di Modrone nr. 30 - Codice Fiscale e P. Iva 09477660964, che potrà essere contattata al numero di
            fax +39 0287348245 - email info@egg.srl
          </p>
          <h2>6. Ambito di comunicazione dei Dati Personali</h2>
          <p>
            Il Titolare del Trattamento raccoglierà e gestirà i Dati Personali attraverso soggetti e/o responsabili
            debitamente autorizzati a svolgere le attività ed i servizi relativi al Programma ai quali sono state
            fornite tutte le istruzioni da seguire al fine di garantire elevati livelli di sicurezza e riservatezza. In
            particolare, tali soggetti utilizzeranno strumenti e supporti - cartacei, magnetici, informatici o
            telematici – idonei a garantire la sicurezza e la riservatezza. I Dati Personali acquisiti e/o raccolti dal
            EGG potranno essere oggetto di comunicazione ai soggetti la cui facoltà di accedere ai dati sia riconosciuta
            dalla legge oltre che alle categorie di seguito illustrate.
          </p>
          <h2>7. Comunicazione dei dati</h2>
          <p>I Dati Personali potranno essere comunicati, nei limiti delle finalità illustrate, a:</p>
          <ol>
            <li>
              i) operatori sanitari (quali a titolo esemplificativo e non esaustivo Medico, Infermiere, Fisioterapista,
              Psicologo) e/o consulenti e/o professionisti di volta in volta coinvolti da EGG nell’ambito della
              realizzazione del Programma ;
            </li>
            <li>
              ii) dipendenti, a vario titolo, di EGG quali, a titolo esemplificativo e non esaustivo Project Manager -
              Assistant Project Manager - Operatori Contact Center, in qualità di soggetti autorizzati;
            </li>
            <li>
              iii) Soggetti specializzati nella realizzazione di esami diagnostici quali a titolo esemplificativo e non
              esaustivo Laboratori analisi, Società di Telemedicina di volta in volta coinvolti da EGG nell’ambito della
              realizzazione del Programma;
            </li>
            <li>
              iv) Soggetti specializzati nella distribuzione logistica di farmaci e/o materiali legati al Programma di
              volta in volta coinvolti da EGG nell’ambito della realizzazione del Programma ;
            </li>
            <li>
              v) Soggetti specializzati nella raccolta e smaltimento di rifiuti sanitare derivanti da servizi oggetto
              del Programma di volta in volta coinvolti da EGG nell’ambito della realizzazione del Programma;
            </li>
            <li>
              vi) soggetti tenuti a conoscere dei dati personali a lei riferibili nell’ambito delle disposizioni in
              materia di Farmacovigilanza (es. Gedeon), quindi nel caso del verificarsi di eventi avversi che, in forza
              di specifiche disposizioni di legge, impongono la comunicazione dei dati personali, inclusi eventualmente
              anche i dati particolari. Questa comunicazione è obbligatoria ai sensi di legge e del codice deontologico
              Farmindustria
            </li>
          </ol>
          <p>
            Nel caso di consenso al trasferimento (sub 2.1. lettera f), i suoi dati personali, nessuno escluso, saranno
            trasferiti a eventuali nuovi Provider, che li tratteranno in qualità di autonomi titolari del trattamento,
            per garantire la prosecuzione in continuità dei servizi oggetto del Programma in caso di nuovo affidamento
            degli stessi.
          </p>
          <h2>8. Periodo di conservazione</h2>
          <p>
            I suoi Dati Personali saranno conservati in una forma che consenta la sua identificazione per un arco
            temporale non superiore al conseguimento delle finalità per il quale gli stessi sono raccolti e trattati in
            conformità agli obblighi di legge e, comunque, per un periodo non superiore a 30 giorni dalla fine per
            qualsiasi ragione del Programma o dalla cessazione della sua adesione al Programma medesimo. Decorso detto
            termine, i suoi Dati Personali non saranno più trattati e saranno cancellati, salvo l’adempimento degli
            obblighi legali.
          </p>
          <h2>9. Diritti dell’interessato</h2>
          <p>
            Relativamente ai Dati Personali Lei potrà, anche in qualità di soggetto esercente la responsabilità
            genitoriale o la tutela ex art. 357 c.c. nel caso sia il minore a partecipare al Programma, mediante invio
            di comunicazione scritta a mezzo raccomandata a/r ad EGG S.r.l., con sede legale in Milano (MI), Via
            Visconti di Modrone nr. 30 – Codice Fiscale e P. Iva 09477660964 oppure all’indirizzo email info@egg.srl:
          </p>
          <ul>
            <li>
              - chiedere ad EGG, quale titolare del trattamento, l'accesso ai Dati Personali, la rettifica o la
              cancellazione degli stessi, o la limitazione del trattamento o di opporsi al trattamento (ex artt. 15, 16,
              17, 18, 19, 21 Reg. Ue 679/2016).
            </li>
            <li>
              - revocare il consenso in qualsiasi momento, senza pregiudicare la liceità del trattamento basata sul
              consenso prestato prima della revoca (ex art. 8, par. 3, Reg. UE 679/2016).
            </li>
          </ul>
          <p>
            I Dati Personali non verranno diffusi e non verranno trasferiti a Paesi terzi o organizzazioni
            internazionali.
          </p>
          <p>
            La informiamo che non esiste un processo decisionale automatizzato, compresa la profilazione di cui all’art.
            22, paragrafi 1 e 4, del GDPR.
          </p>
          <p>
            EGG è tenuta ad agevolare l’esercizio dei suoi diritti e, in particolare, a fornire un riscontro alla sua
            comunicazione scritta senza ingiustificato ritardo e comunque entro 30 giorni dal ricevimento della
            medesima.
          </p>
          <h2>10. Reclamo</h2>
          <p>
            Ove ritenga che il trattamento violi il Reg. UE 679/2016, Lei ha il diritto di proporre reclamo a
            un'autorità di controllo, segnatamente nello Stato membro in cui risiede abitualmente, lavora oppure del
            luogo ove si è verificata la presunta violazione.
          </p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
)

export default PatientPrivacyPage
