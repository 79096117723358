import styled from '@emotion/styled'

const SectionTitle = styled.h2({
  color: '#fff',
  fontFamily: 'Lato',
  fontSize: 22,
  fontWeight: 400,
  textTransform: 'uppercase',
  marginBottom: 16,
})

export default SectionTitle
