import React from 'react'
import { css } from '@emotion/core'
import coverImageXl from '../images/cover-xl.jpg'
import coverImageLg from '../images/cover-lg.jpg'
import coverImageMd from '../images/cover-md.jpg'
import coverImageSm from '../images/cover-sm.jpg'
import mediaQueries from '../styles/media-queries'

const Loading: React.FC<{}> = () => {
  return (
    <div
      css={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: `url(${coverImageSm})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        [mediaQueries('sm')]: {
          backgroundImage: `url(${coverImageSm})`,
        },
        [mediaQueries('md')]: {
          backgroundImage: `url(${coverImageMd})`,
        },
        [mediaQueries('lg')]: {
          backgroundImage: `url(${coverImageLg})`,
        },
        [mediaQueries('xl')]: {
          backgroundImage: `url(${coverImageXl})`,
        },
      }}
    >
      <div
        css={css`
          display: inline-block;
          position: relative;
          width: 80px;
          height: 80px;

          div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 64px;
            height: 64px;
            margin: 8px;
            border: 8px solid #fff;
            border-radius: 50%;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: #fff transparent transparent transparent;
          }

          div:nth-of-type(1) {
            animation-delay: -0.45s;
          }

          div:nth-of-type(2) {
            animation-delay: -0.3s;
          }

          div:nth-of-type(3) {
            animation-delay: -0.15s;
          }

          @keyframes lds-ring {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}
      >
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}

export default Loading
