import React from 'react'
import { css, Global } from '@emotion/core'
import emotionReset from 'emotion-reset'
import Routing from './routing'

function App() {
  return (
    <div>
      <Global
        styles={css`
          ${emotionReset}

          @import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

          body {
            font-family: 'Lato', sans-serif;
          }
        `}
      />
      <Routing />
    </div>
  )
}

export default App
