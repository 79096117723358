import React from 'react'
import { useParams, useRouteMatch, Switch, Route } from 'react-router-dom'
import { verifyCode } from '../api'
import SubscriptionPage from './subscription-page'
import WrongCodePage from './wrong-code-page'
import Loading from './loading'
import { Psp } from '../types'
import PrivacyPage from './privacy-page'

interface State {
  loading: boolean
  error: boolean
  psp?: Psp
}

type Action =
  | {
      type: 'setPsp'
      psp: Psp
    }
  | {
      type: 'error'
    }

const VerifyCode: React.FC<{}> = () => {
  const [state, dispatch] = React.useReducer(reducer, { loading: true, error: false })
  const { code } = useParams<{ code: string }>()
  const { path } = useRouteMatch()

  React.useEffect(() => {
    if (code) {
      verifyCode(code)
        .then((resp) => {
          dispatch({ type: 'setPsp', psp: { ...resp.data, code } })
        })
        .catch((resp) => {
          dispatch({ type: 'error' })
        })
    }
  }, [code])

  if (!code || state.error) {
    return <WrongCodePage />
  } else if (state.loading || !state.psp) {
    return <Loading />
  } else {
    return (
      <Switch>
        <Route exact path={`${path}/privacy`}>
          <PrivacyPage psp={state.psp} />
        </Route>
        <Route exact path={path}>
          <SubscriptionPage psp={state.psp} />
        </Route>
        <Route path={`${path}/*`}>
          <WrongCodePage />
        </Route>
      </Switch>
    )
  }
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'setPsp':
      return { ...state, loading: false, psp: action.psp }
    case 'error':
      return { ...state, loading: false, error: true }
  }
}

export default VerifyCode
