import React from 'react'
import PresentationSection from '../presentation-section'
import PresentationSectionParagraph from '../presentation-section-paragraph'
import List from './list'
import ListElement from './list-element'
import compileFormImg from '../../../images/icona-penna.png'
import callImg from '../../../images/icona-cuffie.png'
import letterImg from '../../../images/icona-lettera.png'
import formNotCompiled from '../../../images/icona-form.png'
import StepImage from './step-image'
import { Psp } from '../../../types'

interface Props {
  psp: Psp
}

const StepsDescription: React.FC<Props> = ({ psp }) => {
  return (
    <React.Fragment>
      <PresentationSection>
        <StepImage src={compileFormImg} alt="Compilazione Form" />
        <PresentationSectionParagraph>
          Per permetterci di <b>offrire i servizi</b> previsti da <b>Tpenso</b> in favore del Suo Centro e dei Suoi
          Pazienti sarà sufficiente che Lei aderisca <b>compilando il form</b> sottostante.
        </PresentationSectionParagraph>
      </PresentationSection>
      <PresentationSection>
        <StepImage src={letterImg} alt="Compilazione Form" />
        <PresentationSectionParagraph>
          Al momento della sottoscrizione del form sotto riportato, <b>riceverà una mail di conferma</b> con il testo
          dell’Informativa privacy e in allegato:
          <List>
            <ListElement>
              <b>Modulo per l’Adesione del Paziente</b>;
            </ListElement>
            <ListElement>
              <b>Leaflet informativo</b> per il <b>Paziente</b>, in cui sono <b>descritti i servizi</b> offerti ed è
              indicata la modalità di <b>Adesione Telefonica</b> (in cui il Paziente chiamando il numero verde aderisce
              senza l’invio del modulo sopra menzionato).
            </ListElement>
          </List>
        </PresentationSectionParagraph>
      </PresentationSection>
      <PresentationSection>
        <StepImage src={formNotCompiled} alt="Compilazione Form" />
        <PresentationSectionParagraph>
          Segnaliamo che il <b>mancato invio del form</b> ci <b>impedirà</b> di attivarci per procedere all'erogazione
          del <b>Programma di Supporto al Paziente</b>.
        </PresentationSectionParagraph>
      </PresentationSection>
      <PresentationSection>
        <StepImage src={callImg} alt="Compilazione Form" />
        <PresentationSectionParagraph>
          Se desidera, siamo a Sua disposizione all'indirizzo mail <a href="mailto:tpenso@egg.srl">tpenso@egg.srl</a> e
          dal Lunedì al Venerdì dalle ore 13:00 alle ore 18:00 al numero verde dedicato 800 199 347
        </PresentationSectionParagraph>
      </PresentationSection>
    </React.Fragment>
  )
}

export default StepsDescription
