import React from 'react'
import mediaQueries from '../../styles/media-queries'

interface Props {
  className?: string
}

const PresentationSectionParagraph: React.FC<Props> = ({ children, className }) => {
  return (
    <div
      className={className}
      css={{
        textAlign: 'center',
        fontFamily: 'Lato',
        fontSize: 18,
        lineHeight: 1.4,
        padding: '0 16px',
        color: '#1d4d65',
        [mediaQueries('sm')]: {
          padding: '0 24px',
        },
        [mediaQueries('md')]: {
          padding: '0 40px',
          fontSize: 20,
        },
        [mediaQueries('lg')]: {
          fontSize: 22,
        },

        b: {
          fontWeight: 600,
          textDecorationColor: '#1d4d65',
        },
      }}
    >
      {children}
    </div>
  )
}

export default PresentationSectionParagraph
