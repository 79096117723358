import React from 'react'

interface Option {
  label: string
  value: string
}

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string
  label: string
  error?: string
  options: Option[]
}

const RadioComponent: React.FC<Props> = ({ name, label, error, options, ...props }) => {
  return (
    <div css={{ marginTop: 32 }}>
      <p css={{ color: '#fff', marginBottom: 16, lineHeight: 1.2 }}>
        {label}
        {error ? <span css={{ color: '#fb4600' }}> - {error}</span> : ''}
      </p>
      {options.map((op) => (
        <label
          key={op.value}
          css={{
            color: '#fff',
            display: 'block',
            position: 'relative',
            paddingLeft: 35,
            paddingTop: 2,
            marginBottom: 24,
            marginTop: 24,
            cursor: 'pointer',
            fontSize: 16,
            userSelect: 'none',
            alignItems: 'center',
            lineHeight: 1.2,
          }}
        >
          {op.label}
          <input
            {...props}
            id={`${name}-${op.value}`}
            type="radio"
            name={name}
            value={op.value}
            checked={props.value === op.value}
            css={{
              position: 'absolute',
              opacity: 0,
              cursor: 'pointer',
              height: 0,
              width: 0,
            }}
          />
          <span
            css={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: 14,
              width: 14,
              borderRadius: '100%',
              backgroundColor: props.value === op.value ? '#c60b5c' : '#fff',
              border: '4px solid #fff',
            }}
          >
            <span />
          </span>
        </label>
      ))}
    </div>
  )
}

export default RadioComponent
