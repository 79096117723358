import React from 'react'
import welcomeImg from '../../images/welcome-black.svg'
import PresentationSection from './presentation-section'
import PresentationSectionParagraph from './presentation-section-paragraph'
import mediaQueries from '../../styles/media-queries'
import { Psp } from '../../types'

interface Props {
  psp: Psp
}

const Welcome: React.FC<Props> = ({ psp }) => {
  return (
    <PresentationSection>
      <img
        css={{
          margin: '0 auto',
          opacity: 1.0,
          width: '80%',
          maxWidth: 500,
          marginBottom: 40,
          [mediaQueries('md')]: {
            marginBottom: 60,
          },
          [mediaQueries('lg')]: {
            marginBottom: 80,
          },
        }}
        src={welcomeImg}
        alt="Welcome"
      />
      <PresentationSectionParagraph>
        Tpenso nasce con l’obiettivo di fornire gratuitamente ai pazienti strumenti per rendere agevole e autonoma la
        somministrazione della terapia.
      </PresentationSectionParagraph>
    </PresentationSection>
  )
}

export default Welcome
