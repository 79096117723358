import axios from 'axios'
import { FormikValues } from 'formik'

const verifyCodeEndpoint = process.env.REACT_APP_VERIFY_CODE_ENDPOINT ?? ''
const newSubscriptionEndpoint = process.env.REACT_APP_NEW_SUBSCRIPTION_ENDPOINT ?? ''

export function verifyCode(code: string) {
  return axios.post(verifyCodeEndpoint, { code })
}

interface NewSubscriptionFormPayload extends FormikValues {
  code: string
}

export function newSubscriptionForm(form: NewSubscriptionFormPayload) {
  return axios.post(newSubscriptionEndpoint, form)
}
