import React from 'react'
import { Switch, Route } from 'react-router-dom'
import SuccessPage from './components/success-page'
import WrongCodePage from './components/wrong-code-page'
import VerifyCode from './components/verify-code'
import PatientPrivacyPage from './components/patient-privacy-page'

const Routing: React.FC<{}> = () => {
  return (
    <Switch>
      <Route exact path="/success">
        <SuccessPage />
      </Route>
      <Route path="/privacy/paziente">
        <PatientPrivacyPage />
      </Route>
      <Route path="/:code">
        <VerifyCode />
      </Route>
      <Route path="/*">
        <WrongCodePage />
      </Route>
    </Switch>
  )
}

export default Routing
