import React from 'react'
import useScrollPosition from '../../utils/scroll-position'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import logoEgg from '../../images/logo-egg.svg'
import logoGedeon from '../../images/logo-gedeon.svg'
import downArrow from '../../images/down-arrow.svg'
import welcomeImage from '../../images/welcome-white.svg'
import mediaQueries from '../../styles/media-queries'
import coverImageXl from '../../images/cover-xl.jpg'
import coverImageLg from '../../images/cover-lg.jpg'
import coverImageMd from '../../images/cover-md.jpg'
import coverImageSm from '../../images/cover-sm.jpg'
import tpensoLogo from '../../images/logo-tpenso-blue.svg'
import { Psp } from '../../types'

interface Props {
  psp: Psp
  onPageExit: () => void
}

const Cover: React.FC<Props> = ({ onPageExit, psp }) => {
  const [exiting, setExiting] = React.useState<boolean>(false)
  const [{ clientPosition }, coverRef] = useScrollPosition(200)
  const containerRef = React.createRef<HTMLDivElement>()

  // Function to start the animation to scroll the cover out of view
  const scrollToTop = React.useCallback(() => {
    if (containerRef.current && !exiting) {
      const scrollHeight = containerRef.current.getBoundingClientRect().height / 2
      setExiting(true)
      window.scroll({ top: scrollHeight, behavior: 'smooth' })
    }
  }, [containerRef, exiting])

  // When the cover is near the bottom scroll out of view
  React.useLayoutEffect(() => {
    if (clientPosition?.bottomPerc < 0.2 && !exiting) {
      scrollToTop()
    }
  }, [clientPosition, containerRef, exiting, onPageExit, scrollToTop])

  // When the cover is scrolled out of view calls the callback onPageExit
  React.useLayoutEffect(() => {
    if (clientPosition?.bottomPerc === 0) {
      onPageExit()
    }
  }, [clientPosition, onPageExit])

  return (
    <CoverContainer ref={containerRef}>
      <CoverContent ref={coverRef}>
        <LogoContainer>
          <CompanyLogo src={logoEgg} alt="Logo Egg" />
          <CompanyLogo src={logoGedeon} alt="Logo Gedeon" />
        </LogoContainer>
        <TitleContainer>
          <Title>
            <TitleImg src={tpensoLogo} />
            <TitleText>Adesione Programma di Supporto al Paziente</TitleText>
          </Title>
          <TitleImage alt="Welcome" src={welcomeImage} />
        </TitleContainer>
        <DownArrowContainer>
          <DownArrow src={downArrow} alt="Scorri in basso" onClick={() => scrollToTop()} />
        </DownArrowContainer>
      </CoverContent>
    </CoverContainer>
  )
}

const bounceAnimation = css`
  @keyframes bounce {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.25);
    }
  }

  animation-name: bounce;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
`

const CoverContainer = styled.div({
  width: '100%',
  height: '200vh',
})

const CoverContent = styled.div({
  width: '100%',
  height: '100vh',
  display: 'flex',
  overflow: 'hidden',
  flexDirection: 'column',
  backgroundImage: `url(${coverImageSm})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundColor: '#c60b5c',
  [mediaQueries('sm')]: {
    backgroundImage: `url(${coverImageSm})`,
  },
  [mediaQueries('md')]: {
    backgroundImage: `url(${coverImageMd})`,
  },
  [mediaQueries('lg')]: {
    backgroundImage: `url(${coverImageLg})`,
  },
  [mediaQueries('xl')]: {
    backgroundImage: `url(${coverImageXl})`,
  },
})

const LogoContainer = styled.div({
  display: 'flex',
  padding: '0 20px',
  alignItems: 'center',
  justifyContent: 'space-between',
  [mediaQueries('sm')]: {
    padding: '0 20px',
  },
  [mediaQueries('md')]: {
    padding: '0 40px',
  },
  [mediaQueries('lg')]: {
    padding: '0 70px',
  },
  [mediaQueries('xl')]: {
    padding: '0 100px',
  },
})

const CompanyLogo = styled.img({
  width: 120,
  height: 120,
  [`${mediaQueries('sm')} and (min-height: 400px)`]: {
    width: 150,
    height: 150,
  },
  [`${mediaQueries('md')} and (min-height: 500px)`]: {
    width: 180,
    height: 180,
  },
  [`${mediaQueries('lg')} and (min-height: 700px)`]: {
    width: 200,
    height: 200,
  },
  [`${mediaQueries('lg')} and (min-height: 900px)`]: {
    width: 250,
    height: 250,
  },
})

const TitleContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 2,
})

const TitleImg = styled.img({
  width: 280,
  margin: 'auto',
  '@media (max-height: 400px)': {
    width: 200,
  },
  [`${mediaQueries('sm')} and (min-height: 400px)`]: {
    width: 400,
  },
  [`${mediaQueries('md')} and (min-height: 500px)`]: {
    width: 450,
  },
  [`${mediaQueries('lg')} and (min-height: 600px)`]: {
    width: 500,
  },
  [`${mediaQueries('xl')} and (min-height: 900px)`]: {
    width: 550,
  },
})

const TitleText = styled.span({
  color: '#1d4d65',
  fontSize: 20,
  lineHeight: 1.5,
  fontFamily: 'Lato',
  fontWeight: 500,
  whiteSpace: 'nowrap',
  '@media (max-height: 400px)': {
    fontSize: 18,
    lineHeight: 1.2,
  },
  [`${mediaQueries('sm')} and (min-height: 400px)`]: {
    fontSize: 24,
    maxWidth: '90%',
  },
  [`${mediaQueries('md')} and (min-height: 500px)`]: {
    fontSize: 26,
  },
  [`${mediaQueries('lg')} and (min-height: 600px)`]: {
    fontSize: 30,
  },
  [`${mediaQueries('xl')} and (min-height: 900px)`]: {
    fontSize: 34,
  },
})

const Title = styled.div({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  justifyContent: 'center',
  maxWidth: '90%',
  margin: '50px 0',
  borderRadius: 10,
  padding: '20px 30px',
  backgroundColor: 'rgba(255, 255, 255, 0.6)',
  '@media (max-height: 400px)': {
    maxWidth: '95%',
    margin: '25px 0',
    padding: '10px 10px',
  },
  [`${mediaQueries('sm')} and (min-height: 400px)`]: {
    maxWidth: '90%',
  },
  [`${mediaQueries('md')} and (min-height: 500px)`]: {
    maxWidth: '80%',
    paddingLeft: 90,
    paddingRight: 90,
  },
  [`${mediaQueries('lg')} and (min-height: 600px)`]: {
    maxWidth: '75%',
    paddingLeft: 90,
    paddingRight: 90,
  },
  [`${mediaQueries('xl')} and (min-height: 800px)`]: {
    maxWidth: '70%',
    padding: '30px 125px',
  },
})

const TitleImage = styled.img({
  opacity: 0.7,
  width: 300,
  marginTop: 30,
  '@media (max-height: 650px)': {
    display: 'none',
  },
  '@media (min-height: 651px)': {
    display: 'block',
    width: 300,
    marginTop: 25,
  },
  [`${mediaQueries('sm')} and (min-height: 400px)`]: {
    width: 400,
    marginTop: 35,
  },
  [`${mediaQueries('md')} and (min-height: 500px)`]: {
    width: 400,
    marginTop: 35,
  },
  [`${mediaQueries('lg')} and (max-height: 770px)`]: {
    display: 'none',
  },
  [`${mediaQueries('lg')} and (min-height: 600px)`]: {
    width: 500,
    marginTop: 40,
  },
  [`${mediaQueries('xl')} and (max-height: 799px)`]: {
    display: 'none',
  },
  [`${mediaQueries('xl')} and (min-height: 800px)`]: {
    width: 600,
    marginTop: 40,
  },
})

const DownArrowContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  padding: '10px 0',
  [mediaQueries('sm')]: {
    padding: '15px 0',
  },
  [mediaQueries('md')]: {
    padding: '20px 0',
  },
  [mediaQueries('lg')]: {
    padding: '25px 0',
  },
  [mediaQueries('xl')]: {
    padding: '30px 0',
  },
})

const DownArrow = styled.img(
  {
    height: 40,
    width: 45,
    cursor: 'pointer',
    [`${mediaQueries('sm')}`]: {
      height: 45,
      width: 50,
    },
    [`${mediaQueries('md')}`]: {
      height: 50,
      width: 55,
    },
    [`${mediaQueries('lg')}`]: {
      height: 55,
      width: 65,
    },
    [`${mediaQueries('xl')}`]: {
      height: 60,
      width: 80,
    },
    '@media (min-height: 400px) and (max-height: 500px)': {
      marginTop: -50,
      height: 45,
      width: 50,
    },
    '@media (min-height: 600px) and (max-height: 700px)': {
      height: 45,
      width: 50,
    },
  },
  bounceAnimation
)

export default Cover
